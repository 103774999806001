 <template>
	<el-dialog 
		top="1vh" title="发票同步" 
		append-to-body 
		custom-class="el-dialog-s" 
		width="80%" 
		:visible.sync="is_show_in_page"
        @close="invoice_lsit_close"
	>
		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="公司名称">
				<el-input class="el_input" v-model="form.company_name" placeholder="请输入公司名称(必填)" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发票号码">
				<el-input class="el_input" v-model="form.tax_code" placeholder="请输入发票号码" clearable></el-input>
			</el-form-item>
             <el-form-item class="el_form_item" label="id">
				<el-input class="el_input" v-model="form.id" placeholder="请输入id" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="全部" value="0"></el-option>
					<el-option label="待开出" value="2"></el-option>
					<el-option label="已开出" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="init">查询</el-button>
                <el-button type="primary" @click="batch_invoice_open">批量开出</el-button>
			</el-form-item>
		</el-form>
        <div class="total_info">
			<span v-if="truck_tord_num_list.length >0">勾选总条数:{{ truck_tord_num_list.length }}</span>
			<span v-if="truck_tord_num_list.length >0">勾选总开票金额:{{ total_invoice }}元</span>
		</div>
        <!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" 
             @selection-change="invoice_tick"
            >
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column label="id">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
                <el-table-column label="运单编号">
					<template slot-scope="scope">
                        <div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="公司名称">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发票代码">
					<template slot-scope="scope">
                        <div class="tab_line_item">{{scope.row.tax_code}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发票号码">
					<template slot-scope="scope">
                        <div class="tab_line_item">{{scope.row.tax_num}}</div>
					</template>
				</el-table-column>
                <el-table-column label="发票金额">
					<template slot-scope="scope">
                        <div class="tab_line_item">{{scope.row.tax_much}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单开票金额">
					<template slot-scope="scope">
                        <div class="tab_line_item">{{scope.row.this_invoiced_total}}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
                <!-- 确定开出 -->
                <el-dialog top="20vh" title="确定开出"  width="1050px" @close="invoice_close" :modal="false" :visible.sync="invoice_confirm_isshow">
                    <el-form class="form_ordinary" label-width="165px">
                    <div class="tab1_inner" style="display: flex">
                        <el-form-item class="el_form_item" label="发票号码"  style="width: 450px; margin: 10px 0px">
                            <el-input
                            type="textarea"
                            style="width:268px"
                            placeholder="请输入发票号码"
                            v-model="invoice_number_list">
                            </el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="发票代码号"  style="width: 450px; margin: 10px 0px">
                            <el-input
                            type="textarea"
                            style="width:268px"
                            placeholder="请输入发票代码号"
                            v-model="invoice_code_list">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="btn_area" style="text-align: center;">
                            <el-button type="primary" @click="invoice_done_sub">提交</el-button>
                        </div>
                    </el-form>
                </el-dialog>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,
                form:{
                     company_name:'',//公司名称
                     invoice_status:'2',//发票状态
                     id:"",
					 tax_code:'',//发票号码
                },
                list:{
                    data:[],
                    loading: false,//loading特效
                },
                invoice_confirm_isshow:false,
                truck_tord_num_list:[],//选择得运单编号
                invoice_number_list:'',
                invoice_code_list:'',
                total_invoice:'',//勾选总开票金额
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.is_show_in_page=false
				}else {
					this.is_show_in_page=true
				}
			},
		},
		methods: {
            //列表弹窗关闭
            invoice_lsit_close(){
                this.form.company_name = ''
                this.form.id = ''
                this.form.invoice_status = "2"
				this.form.tax_code = ''
                this.list.data = []
            },
            //弹窗关闭 清空数据
            invoice_close(){
                this.invoice_number_list = ''
                this.invoice_code_list = ''
            },
			//提交
			invoice_done_sub(){
                this.$my.other.confirm({
					content: "确定开出?",
					confirm: () => {
					//调用接口
					this.$my.net.req({
						data: {
						mod: "truck_tord_real_time",
						ctr: "invoice_code_order",
						truck_tord_num_list: this.truck_tord_num_list,
						invoice_number_list:this.invoice_number_list,
						invoice_code_list:this.invoice_code_list
						},
						callback: (data) => {
                            this.$my.other.msg({
                                type:'success',
                                str:'提交成功'
                            })
                            this.invoice_confirm_isshow =false
						},
					});
					},
				});
			},
            invoice_tick(item){
                var arr = []
                var total_invoice = 0
                item.forEach(item => {
                    arr.push(item.truck_tord_num)
                    total_invoice +=Number(item.this_invoiced_total)
                });
                this.total_invoice = total_invoice.toFixed(2)
                this.truck_tord_num_list =arr
            },
            //批量开出
            batch_invoice_open(){
                if(this.truck_tord_num_list.length == 0){
                    this.$my.other.msg({
						type:'warning',
						str:'请选择'
					})
                    return
                }
                this.invoice_confirm_isshow = true
            },
			//页面初始化
			init(){
                this.list.loading = true
                this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'tord_invoice_list_admin',
                        ...this.form
                    },callback:(data)=>{
                       this.list.data = data.list
                    }
                })
                 this.list.loading = false
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:1000px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}

	.total_info {
	    text-align: right;
		font-size: 15px;
		margin-bottom: 10px;
		margin-right: 1px;
		color: #606266;
		span {
			margin-left: 10px;
		}
	}
</style>